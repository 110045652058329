@keyframes blink {
  0% {
    color: white;
  }
  50% {
    color: black;
  }
  100% {
    color: white;
  }
}

.animate-blink {
  animation: blink 2s infinite;
}

.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 7px; /* Adjust size as needed */
  height: 7px; /* Adjust size as needed */
  background-color: black;
  border-radius: 50%;
  margin: 0 2px;
}

.animate-dot-1 {
  animation: move-dot 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite; /* Adjust timing function */
}

.animate-dot-2 {
  animation: move-dot 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite 0.5s; /* Adjust timing function and delay */
}

.animate-dot-3 {
  animation: move-dot 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite 1s; /* Adjust timing function and delay */
}

@keyframes move-dot {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
